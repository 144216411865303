import { createRouter, createWebHistory, type RouteLocationRaw, type RouteRecordRaw } from 'vue-router'

import JobList from '@/components/Jobs/JobList.vue'
import JobView from '@/components/Jobs/JobView.vue'
import JobForm from '@/components/Jobs/JobForm/JobForm.vue'

import ExamList from '@/components/Exams/ExamList.vue'
import ExamView from '@/components/Exams/ExamView.vue'

import ExamDraftList from '@/components/ExamDrafts/ExamDraftList.vue'
import ExamDraftForm from '@/components/ExamDrafts/ExamDraftForm.vue'
import ExamDraftExport from '@/components/ExamDrafts/ExamDraftExport.vue'

import QuestionList from '@/components/Questions/QuestionList.vue'
import QuestionView from '@/components/Questions/QuestionView.vue'

import QuestionDraftList from '@/components/QuestionDrafts/QuestionDraftList.vue'
import QuestionDraftForm from '@/components/QuestionDrafts/QuestionDraftForm.vue'

import UserList from '@/components/Users/UserList.vue'
import UserView from '@/components/Users/UserView.vue'
import UserForm from '@/components/Users/UserForm.vue'

import BundleList from '@/components/Bundles/BundleList.vue'
import BundleForm from '@/components/Bundles/BundleForm.vue'

import Container from '@/components/Container.vue'
import SignIn from '@/components/SignIn.vue'
import Dashboard from '@/components/Dashboard.vue'
import ForgotPassword from '@/components/ForgotPassword.vue'
import { usersModule } from '@/store/users/module'
import navigationModule from '@/store/navigation/module'

const notAdminRedirect = (routeName: string): RouteLocationRaw | undefined => {
    const user = usersModule.state.user
    // If the user is not an admin, redirect to the provided route name
    if (user && user.role === 'Admin') {
        return
    } else {
        return { name: routeName }
    }
}

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        component: Dashboard,
        // Dashboard is not a named route - use default child route instead
        async beforeEnter (to, from, next) {
            
            const validUser = await usersModule.actions.validateUser()
            // If a user is not logged in, redirect to login page
            if (validUser) {
                next()
            } else {
                next({ name: 'login', query: { 'redirect': to.fullPath } })
            }
        },
        children: [
            {
                path: '',
                redirect: { name: 'job-list' },
            }, {
                path: 'jobs',
                component: Container,
                children: [
                    {
                        path: '',
                        name: 'job-list',
                        component: JobList,
                    }, {
                        path: 'create',
                        name: 'job-create',
                        component: JobForm,
                        beforeEnter (to, from, next) {
                            const redirect = notAdminRedirect('job-list')
                            redirect ? next(redirect) : next()
                        },
                    }, {
                        path: ':jobId',
                        component: Container,
                        children: [
                            {
                                path: '',
                                name: 'job-view',
                                component: JobView,
                            }, {
                                path: 'edit',
                                name: 'job-edit',
                                component: JobForm,
                                beforeEnter (to, from, next) {
                                    const redirect = notAdminRedirect('job-list')
                                    redirect ? next(redirect) : next()
                                },
                            },
                        ],
                    },
                ],
            }, {
                path: 'bundles',
                component: Container,
                beforeEnter (to, from, next) {
                    const redirect = notAdminRedirect('job-list')
                    redirect ? next(redirect) : next()
                },
                children: [
                    {
                        path: '',
                        name: 'bundle-list',
                        component: BundleList,
                    }, {
                        path: 'create',
                        name: 'bundle-create',
                        component: BundleForm,
                    }, {
                        path: ':bundleId',
                        component: Container,
                        children: [
                            {
                                path: '',
                                name: 'bundle-edit',
                                component: BundleForm,
                            },
                        ],
                    },
                ],
            }, {
                path: 'exams',
                component: Container,
                beforeEnter (to, from, next) {
                    const redirect = notAdminRedirect('job-list')
                    redirect ? next(redirect) : next()
                },
                children: [
                    {
                        path: '',
                        name: 'exam-list',
                        component: ExamList,
                    }, {
                        path: ':examId',
                        component: Container,
                        children: [
                            {
                                path: '',
                                name: 'exam-view',
                                component: ExamView,
                            },
                        ],
                    },
                ],
            }, {
                path: 'exam-drafts',
                component: Container,
                beforeEnter (to, from, next) {
                    const redirect = notAdminRedirect('job-list')
                    redirect ? next(redirect) : next()
                },
                children: [
                    {
                        path: '',
                        name: 'exam-draft-list',
                        component: ExamDraftList,
                    }, {
                        path: 'create',
                        name: 'exam-draft-create',
                        component: ExamDraftForm,
                    }, {
                        path: ':examDraftId',
                        component: Container,
                        children: [
                            {
                                path: '',
                                name: 'exam-draft-edit',
                                component: ExamDraftForm,
                            }, {
                                path: 'export',
                                name: 'exam-draft-export',
                                component: ExamDraftExport,
                            },
                        ],
                    },
                ],
            }, {
                path: 'questions',
                component: Container,
                beforeEnter (to, from, next) {
                    const redirect = notAdminRedirect('job-list')
                    redirect ? next(redirect) : next()
                },
                children: [
                    {
                        path: '',
                        name: 'question-list',
                        component: QuestionList,
                    }, {
                        path: ':questionId',
                        name: 'question-view',
                        component: QuestionView,
                    },
                ],
            }, {
                path: 'question-drafts',
                component: Container,
                children: [
                    {
                        path: '',
                        name: 'question-draft-list',
                        component: QuestionDraftList,
                        beforeEnter (to, from, next) {
                            const redirect = notAdminRedirect('job-list')
                            redirect ? next(redirect) : next()
                        },
                    }, {
                        path: 'create',
                        component: Container,
                        children: [
                            {
                                path: '',
                                name: 'question-draft-create',
                                component: QuestionDraftForm,
                            },
                        ],
                    }, {
                        path: ':questionDraftId',
                        component: Container,
                        children: [
                            {
                                path: '',
                                name: 'question-draft-edit',
                                component: QuestionDraftForm,
                            },
                        ],
                    },
                ],
            }, {
                path: 'users',
                component: Container,
                beforeEnter (to, from, next) {
                    const redirect = notAdminRedirect('job-list')
                    redirect ? next(redirect) : next()
                },
                children: [
                    {
                        path: '',
                        name: 'user-list',
                        component: UserList,
                    }, {
                        path: 'create',
                        name: 'user-create',
                        component: UserForm,
                    }, {
                        path: ':userId',
                        component: Container,
                        children: [
                            {
                                path: '',
                                name: 'user-view',
                                component: UserView,
                            }, {
                                path: 'edit',
                                name: 'user-edit',
                                component: UserForm,
                            },
                        ],
                    },
                ],
            }, {
                path: 'settings',
                name: 'settings',
                component: UserForm,
            },
        ],
    }, {
        path: '/login',
        name: 'login',
        component: SignIn,
        async beforeEnter (to, from, next) {
            const validUser = await usersModule.actions.validateUser()
            // If a user is already logged in, redirect to dashboard
            if (validUser) {
                next('/')
            } else {
                await usersModule.actions.logOut()
                next()
            }
        },
    }, {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
    }, {
        path: '/:pathMatch(.*)*',
        redirect: { name: 'job-list', params: {} },
    },
]

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
})

router.afterEach((to, from) => {
    const routeItem = {
        name: (from.name || from.path) as string,
        params: from.params,
    }
    navigationModule.actions.addRouteToHistory(routeItem)
})

export const isSidebarVisible = () => {
    const routeName = router.currentRoute.value.name
    return (routeName && typeof routeName === 'string'
    && [ 'job-view', 'question-draft-create', 'question-draft-edit' ].includes(routeName)) 
}

export {
    router,
    routes,
}

export default router