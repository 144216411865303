<template>
    <div
        v-if="job && currentUser"
        class="job-info"
        :style="addCustomWidth"
    >
        <div class="job-info__progress-key">
            <div class="job-info__progress-label">
                JOB PROGRESS
            </div>
            <div class="job-info__progress-key-row">
                <div 
                    class="job-info__progress-key-item" 
                >
                    {{ currentUser.role === 'Writer' ? 'Job Progress:' : 'Writer:' }} {{ job.writerPercent }}%
                </div>
                <div 
                    v-if="currentUser.role !== 'Writer'" 
                    class="job-info__progress-key-item overall"
                >
                    {{ currentUser.role === 'Editor' ? 'Editor:' : 'Completed:' }} {{ job.editorPercent }}%
                </div>
            </div>
        </div>
        <div :class="`progress-bar${currentUser.role !== 'Writer' ? ' progress-bar--admin' : ''}`">
            <div class="job-info__progress-bar">
                <div class="job-info__progress-goal">
                    <div 
                        class="job-info__count"
                        :style="`width: ${job.writerPercent}%`"
                    />
                    <div 
                        v-if="currentUser.role !== 'Writer'"
                        class="job-info__count overall"
                        :style="`width: ${job.editorPercent}%`"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import type { CMS } from '@pocketprep/types'
import { usersModule } from '@/store/users/module'

@Component
export default class JobInfo extends Vue {
    @Prop() readonly job?: CMS.Cloud.JobWithMetrics
    @Prop() readonly customWidth?: string

    get currentUser () {
        return usersModule.getters.getCurrentUser()
    }

    get addCustomWidth () {
        return this.customWidth ? { width: this.customWidth } : {}
    }
}
</script>


<style lang="scss" scoped>

.job-info {
    width: 50px;
    color: $brand-black;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    min-height: 75px;
    padding-top: 10px;

    &__name {
        flex: 1;
        min-width: 175px;
        margin-bottom: 10px;
        cursor: pointer;
        user-select: none;
    }

    &__progress-key {
        display: flex;
        justify-content: space-between;
        margin: 7px 5px 7px 5px;
        width: 100%;
        text-align: center;
        font-size: 13px;
    }

    &__progress-label {
        font-weight: 600;
        font-size: 13px;
        color: $brand-black;
        margin-left: 8px;
        letter-spacing: 1px;
    }

    &__progress-key-row {
        margin-right: 10px;
    }

    &__progress-key-item {
        display: inline-block;
        margin: 0 7px;
        font-size: 13px;
        color: $brand-black;

        &::before {
            content: '';
            width: 12px;
            height: 12px;
            background: url('@/assets/img/job-progress-pattern.svg'), rgba(20, 202, 158, 0.2);
            border: 1px solid $scifi-takeout;
            border-radius: 2px;
            display: inline-block;
            margin-right: 6px;
            vertical-align: top;
            margin-top: -2px;
        }

        &.overall::before {
            background: $spectral-green;
        }
    }

    &__progress-bar {
        display: flex;
        align-items: center;
    }

    &__progress-goal {
        flex: 5;
        height: 14px;
        background-color: $barely-background;
        border: 1px solid $scifi-takeout;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
    }

    &__count {
        height: 100%;
        background: url('@/assets/img/job-progress-pattern.svg'), rgba(20, 202, 158, 0.2);
        position: absolute;
        left: 0;
        top: 0;
    }

    &__count.overall {
        background: $spectral-green;
    }
}

</style>
