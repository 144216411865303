import { reactive } from 'vue'
import state from './state'
import actions from './actions'
import getters from './getters'

export const examsModule = {
    state: reactive(state),
    actions,
    getters,
}

export default examsModule
